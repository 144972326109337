<template>
  <fixed-header :threshold="300">
    <nav
      id="V2HeaderWrap"
      class="navigation"
      :class="{
        dark_mode: $store.state.darkHeader,
        wide_mode: $store.state.wideHeader,
      }"
    >
      <div class="navigation__header">
        <!-- pc ======= -->
        <div class="logo-box">
          <router-link to="/home">
            <CImg src="/img/threespace/logo/title.svg" />
            <span>Bring Art to Life,<br /> Life to Art</span>
          </router-link>
        </div>
        <div class="right-menu__wrapper">
          <ul class="right-menu">
            <HeaderDropdownArtists />
            <HeaderDropdownW3 />
            <router-link
              tag="li"
              to="/little-picasso"
              active-class="active"
              class="shinhan"
            >
              <a>
                {{ $t("menu.picasso") }}
                <div class="shinhan-logo" />
              </a>
            </router-link>
            <HeaderDropdownArtpool />
            <HeaderDropdownAbout />
          </ul>
          <ul class="button-wrapper">
            <HeaderDropdownWallet ref="headerDropdownWallet" />
            <section class="language">
              <button
                class="language__button"
                @click.stop="showSortByFilter"
                :class="{ active: isVisibleLanguage }"
              >
                <CImg src="/img/threespace/icon/language.svg" />
                <span v-if="isActiveLanguage('ko')">KOR</span>
                <span v-else-if="isActiveLanguage('en')">ENG</span>
                <CImg src="/img/threespace/icon/chevron.svg" />
              </button>
              <div
                class="language__list"
                :class="{ open: isVisibleLanguage }"
                v-click-outside="() => showSortByFilter(false)"
              >
                <button @click="setActiveLanguage('ko')" class="item">
                  KOR
                </button>
                <button @click="setActiveLanguage('en')" class="item">
                  ENG
                </button>
              </div>
            </section>
          </ul>

          <!-- mobile ======= -->
          <button
            class="mobile__hamburger"
            @click="showMobileMenu = !showMobileMenu"
          >
            <img src="/img/threespace/icon/hamburger-bar.svg" />
          </button>
          <div class="arrow_animation">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path
                d="M1.61719 6.93872L12.0245 0.930034L12.0245 12.9474L1.61719 6.93872Z"
                fill="white"
                stroke="black"
                stroke-width="0.7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path
                d="M1.61719 6.93872L12.0245 0.930034L12.0245 12.9474L1.61719 6.93872Z"
                fill="white"
                stroke="black"
                stroke-width="0.7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path
                d="M1.61719 6.93872L12.0245 0.930034L12.0245 12.9474L1.61719 6.93872Z"
                fill="white"
                stroke="black"
                stroke-width="0.7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path
                d="M1.61719 6.93872L12.0245 0.930034L12.0245 12.9474L1.61719 6.93872Z"
                fill="white"
                stroke="black"
                stroke-width="0.7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path
                d="M1.61719 6.93872L12.0245 0.930034L12.0245 12.9474L1.61719 6.93872Z"
                fill="white"
                stroke="black"
                stroke-width="0.7"
              />
            </svg>
          </div>
        </div>
      </div>

      <!-- mobile menu ============ -->
      <div :class="['mobile', { 'mobile--menu-open': showMobileMenu }]">
        <div class="mobile__wrapper">
          <section class="mobile__header">
            <div class="logo-box">
              <router-link to="/home">
                <CImg src="/img/threespace/logo/title.svg" />
              </router-link>
            </div>
            <button @click="onClickOutside">
              <img src="/img/threespace/icon/close.svg" />
            </button>
          </section>
          <ul class="mobile__menu">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("menu.artists") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/drops')">
                      {{ $t("menu.drops") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/artist')">
                      {{ $t("menu.list") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/apply')">
                      {{ $t("menu.apply") }}
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header> W3 </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/ordinals')">
                      {{ $t("menu.ordinals") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/ai')">
                      A.I.
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/meme')">
                      MEME
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <li class="menu">
              <a
                class="shinhan"
                href="#"
                @click.prevent="toLinkInMobile('/little-picasso')"
              >
                <span>{{ $t("menu.picasso") }}</span>
                <div class="shinhan-logo" />
              </a>
            </li>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("menu.artPool") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a
                      href="#"
                      @click.prevent="toLinkInMobile('/art-pool/nft/gallery')"
                    >
                      {{ $t("menu.artPoolSub.viewGallery") }}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      @click.prevent="toLinkInMobile('/art-pool/nft')"
                    >
                      {{ $t("menu.artPoolSub.exhibit") }}
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>3SPACE</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/about')">
                      {{ $t("menu.3spaceSub.about") }}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      @click.prevent="toLinkInMobile('/art-pool/pace2.0')"
                      >$PACE</a
                    >
                  </li>
                  <li>
                    <a href="https://docs.3space.art/" target="_blank">
                      {{ $t("menu.3spaceSub.whitePaper") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/features')">
                      {{ $t("menu.3spaceSub.features") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/guide')">
                      {{ $t("menu.3spaceSub.guide") }}
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Language</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <button
                      @click="setActiveLanguage('ko')"
                      class="language__button"
                      :class="{ active: isActiveLanguage('ko') }"
                    >
                      KO
                    </button>
                  </li>
                  <li>
                    <button
                      @click="setActiveLanguage('en')"
                      class="language__button"
                      :class="{ active: isActiveLanguage('en') }"
                    >
                      EN
                    </button>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </ul>
          <section class="mobile__href">
            <a href="https://medium.com/@3spaceart" target="_blank">
              <img src="/img/threespace/logo/medium.svg" />
            </a>
            <a href="https://twitter.com/3spaceart" target="_blank">
              <img src="/img/threespace/logo/twitter.svg" />
            </a>
            <a href="https://t.me/threespaceart_chat" target="_blank">
              <img src="/img/threespace/logo/telegram.svg" />
            </a>
            <a href="https://www.instagram.com/3space.art/" target="_blank">
              <img src="/img/threespace/logo/instagram.svg" />
            </a>
            <a href="https://github.com/3spaceart" target="_blank">
              <img src="/img/threespace/logo/github.svg" />
            </a>
          </section>
          <!--
          <ul class="sub_menu">
            <li v-if="!isAuthenticated">
              <a href="#" @click.prevent="toLinkInMobile('/login')">LOGIN</a>
            </li>
            <HeaderDropdownAccount v-if="isAuthenticated" :mobile-mode="true" />
          </ul>
          -->
          <div v-if="!isAuthenticated" class="btn-wrap">
            <b-button
              class="mobile__wallet-connect-btn"
              @click="showHeaderDropdownWallet"
            >
              <span class="text-white">{{ $t("wallet.connect") }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </nav>
  </fixed-header>
</template>

<script>
import HeaderDropdownArtists from "@/containers/HeaderDropdownArtists";
import HeaderDropdownArtpool from "@/containers/HeaderDropdownArtpool";
import HeaderDropdownAbout from "@/containers/HeaderDropdownAbout";
import HeaderDropdownW3 from "@/containers/HeaderDropdownW3";
//import HeaderDropdownAccount from "@/containers/HeaderDropdownAccount";
import HeaderDropdownWallet from "@/containers/HeaderDropdownWallet";
import { mapActions, mapGetters, mapState } from "vuex";
import vClickOutside from "v-click-outside";
import FixedHeader from "vue-fixed-header";

export default {
  name: "Header",
  components: {
    HeaderDropdownArtists,
    HeaderDropdownArtpool,
    HeaderDropdownAbout,
    HeaderDropdownW3,
    //HeaderDropdownAccount,
    HeaderDropdownWallet,
    vClickOutside,
    FixedHeader,
  },
  data() {
    return {
      showMobileMenu: false,
      isVisibleLanguage: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.requestAccountBalance();
    });
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions("wallet", ["getBalance"]),
    showTerms(_code) {
      this.$router.push("/terms/" + _code);
    },
    setActiveLanguage(_lang) {
      localStorage.setItem("language", _lang);

      this.$emit("language-changed", _lang);
      window.location.reload();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    toLinkInMobile(link) {
      this.showMobileMenu = false;
      this.$router.push(link);
    },
    isActiveLanguage(_lang) {
      return this.activeLanguage === _lang;
    },
    requestAccountBalance() {
      this.getBalance().then(() => {});
    },
    onClickOutside() {
      this.showMobileMenu = false;
    },
    // Mobile
    showHeaderDropdownWallet() {
      this.$refs.headerDropdownWallet.showWalletPopupModal();
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.isVisibleLanguage = value;
      else {
        this.isVisibleLanguage = !this.isVisibleLanguage;
      }
    },
  },
};
</script>

<style scoped>
.v-icon.v-icon {
  color: #000 !important;
}
</style>
